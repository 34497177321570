














































































































import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { Content } from "@/models/content";
import { mdiOpenInNew, mdiPhone, mdiFlag } from "@mdi/js";
import WVideo from "@/components/WVideo.vue";
import Question from "@/components/Question.vue";
import { db } from "@/plugins/firebase";
import { Answer } from "@/models/answer";
import { RibbonService } from "@/services/ribbonService";
import { addDoc, collection, doc, setDoc, Timestamp } from "firebase/firestore";
import { User } from "firebase/auth";

@Component({
    name: "WContent",
    components: {
        WVideo,
        Question
    }
})
export default class WContent extends Vue {
    @Prop({ required: true })
    private readonly content!: Content;

    @Prop({ required: true })
    private readonly answers!: Answer[];

    @Prop({ required: true })
    private readonly day!: string;

    @Prop({ required: true })
    private readonly user!: User;

    public videoFinished = false;

    public questionVideoFinished = false;

    public report = {
        valid: false,
        open: false,
        issue: "",
        details: ""
    }

    public rules = [(v: string) => !!v || "Required"]

    private readonly icons = {
        mdiFlag
    }

    created() {
        if(!this.content.video)
            this.videoFinished = true;
        if(!this.content.question?.video || this.content.question.video.optional)
            this.questionVideoFinished = true;
    }

    private icon(path: string, data?: string) {
        return `
            <svg xmlns="http://www.w3.org/2000/svg" height="0.9em" viewbox="0 0 24 24" role="img" fill="currentColor" data-action="${data || ""}" class="action">
                <path d="${path}"></path>
            </svg>
        `;
    }

    private link(link: string, href: string) {
        return `
            <a href="${href}" target="__blank">${link}${this.icon(mdiOpenInNew)}</a>
        `;
    }

    public get parsedText() {
        return this.parse(this.content.text);
    }

    public get parsedQuestionText() {
        if (!this.selected) return [];
        let r = this.parse(this.content.question?.text[this.selected.input]) || [];
        r = [...r, ...this.parse(this.content.question?.common || [])];
        return r;
    }

    public parse(txt?: string[]) {
        return txt?.map(t => {
            return t
                .replace(/tel:\d{3}-\d{3}-\d{4}/ig, this.icon(mdiPhone))
                .replace(/<nick name>/gi, this.user.displayName || "")
                .replace(/<ribbon (\d+)>/gi, (_, g1) => String(RibbonService.compute(this.answers, g1)))
                .replace(/<(.+) link:(.+)>/gi, (_, g1, g2) => this.link(g1, g2));
        }) || [];
    }

    public get imgClass() {
        const classes = [];
        if(this.content.pinkie) {
            if(this.content.pinkie.animation)
                classes.push(this.content.pinkie.animation);
        }
        return classes;
    }

    public get done() {
        return this.videoFinished && (!this.hasQuestion || this.answered) && this.questionVideoFinished;
    }

    @Watch("done", { immediate: true })
    onDone(value: boolean) {
        if(value)
            this.$emit("done");
    }

    public async onSelect(v: number) {
        if(this.content.question) {
            const correct = this.content.question.correct === v;
            setDoc(doc(db, "users", this.uid, "answers", this.content.id), {
                input: v,
                correct,
                day: this.day,
                date: Timestamp.now()
            });
        }
    }

    public get uid() {
        return this.user.uid;
    }

    public get selected(): Answer | undefined {
        return this.answers.find(a => a.id === this.content.id);
    }

    public get hasQuestion() {
        return !!this.content.question;
    }

    public get answered() {
        return this.selected !== undefined;
    }

    public get hasCorrectImage() {
        return this.content.question?.pinkie && this.content.question.pinkie.correct && this.selected?.correct;
    }

    public get hasIncorrectImage() {
        return this.content.question?.pinkie && this.content.question.pinkie.incorrect && this.selected?.correct === false;
    }

    public get correctImg() {
        return this.content.question?.pinkie?.correct;
    }

    public get incorrectImg() {
        return this.content.question?.pinkie?.incorrect;
    }

    public get questionImgClass() {
        return this.content.question?.pinkie?.class;
    }

    public get questionCols() {
        if(this.selected?.correct && this.hasCorrectImage)
            return 6;
        if(this.selected?.correct === false && this.hasIncorrectImage)
            return 6;
        return 8;
    }

    openReport() {
        this.report.open = true;
        this.report.issue = window.getSelection()?.toString() || "";
    }

    submitReport() {
        (this.$refs["report-form"] as any).validate();

        if (!this.report.valid) return;

        addDoc(collection(db, "reports"), {
            contentId: this.content.id,
            user: this.user.email,
            userId: this.uid,
            date: Timestamp.now(),
            issue: this.report.issue,
            details: this.report.details,
            resolved: false
        });
        this.report.open = false;
    }

    close() {
        this.report.open = false;
        this.report.valid = false;
        this.report.issue = "";
        this.report.details = "";
        (this.$refs["report-form"] as any).resetValidation();
    }
}
