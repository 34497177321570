






















import { Vue, Component, Prop, Emit } from "vue-property-decorator";

@Component({
    name: "Question"
})
export default class Question extends Vue {
    @Prop({ type: String, required: true })
    private readonly question!: string;

    @Prop({ type: Array, required: true })
    private readonly options!: string[];

    @Prop({ type: Number })
    private readonly override?: number;

    @Prop({ type: Number, default: 0 })
    private readonly timeout!: number;

    private time = 0;
    private interval = 1000;
    private x = 0;

    private selected = false;

    created() {
        if(this.override !== undefined)
            this.selected = true;
    }

    mounted() {
        if(this.timeout > 0)
            this.x = setInterval(() => this.time += this.interval, this.interval);
    }

    beforeDestroy() {
        clearInterval(this.x);
    }

    @Emit("select")
    onSelect(value: any) {
        this.selected = true;
        clearInterval(this.x);
        return value;
    }
}
