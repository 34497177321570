































import { Vue, Component, Prop, Emit, Watch } from "vue-property-decorator";
import WContent from "@/components/WContent.vue";
import { mdiArrowLeft, mdiArrowRight } from "@mdi/js";
import { ContentManager } from "@/services/contentManager";
import { Answer } from "@/models/answer";
import { db } from "@/plugins/firebase";
import { Day } from "@/models/day";
import { Unsubscribe } from "@firebase/auth";
import { Timestamp, onSnapshot, collection, setDoc, doc, DocumentReference, getDocFromCache } from "firebase/firestore";
import { DayManager } from "@/services/dayManager";

@Component({
    name: "WDay",
    components: {
        WContent
    }
})
export default class WDay extends Vue {
    // @Prop({ required: true })
    // private readonly loader!: () => Promise<{ manager: ContentManager}>;

    @Prop({ required: true })
    private readonly dayManager!: DayManager;

    @Prop({ required: true })
    private readonly active!: DocumentReference;

    private activeDay: Day | null = null;

    private manager: null | ContentManager = null;

    private answers: Answer[] = [];

    private loading = true;

    private contentDone = false;

    public readonly icons = {
        mdiArrowLeft,
        mdiArrowRight
    }

    async created() {
        await this.setActiveDay();

        if (!this.activeDay)
            throw new Error("Active day not set!");

        const loader = this.dayManager.getManagerLoader(this.activeDay.day);

        if (!loader)
            throw new Error("Loader missing!");

        const [manager] = await Promise.all([loader(), this.loadAnswers()]);
        this.manager = manager.manager;

        if (!this.activeDay.current)
            setDoc(this.doc(), {
                current: this.manager.firstId,
                max: this.manager.firstId,
                startDate: Timestamp.now()
            }, { merge: true });


        const d = await getDocFromCache(this.doc());

        if (d.data()?.current) {
            this.manager.set(d.data()?.current);
            this.contentDone = this.manager.isBack(d.data()?.max || "");
        }

        this.loading = false;
    }

    private doc(){
        if (!this.activeDay)
            throw new Error("NO ACTIVE DAY");
        return doc(db, "users", this.$store.state.user.uid, "days", this.activeDay.day.toString());
    }

    beforeDestroy() {
        if (this.unsubAnswers)
            this.unsubAnswers();
        if (this.unsubActiveDay)
            this.unsubActiveDay();
    }

    private unsubActiveDay: Unsubscribe | null = null;
    setActiveDay() {
        return new Promise<void>((res) => {
            this.unsubActiveDay = onSnapshot(this.active, snap => {
                this.activeDay = { id: snap.id, ...snap.data() } as Day;
                res();
            });
        });
    }

    @Watch("activeDay")
    onActiveDayChagne(next: Day) {
        if (next.current && this.manager) {
            this.manager.set(next.current);
        }
        if (next.current && this.manager && !this.contentDone)
            this.contentDone = this.manager.isBack(next.max || "");
    }

    private unsubAnswers: Unsubscribe | null = null;
    async loadAnswers() {
        // return this.$bind("answers", db.collection("users").doc(this.$store.state.user.uid).collection("answers"));
        return new Promise<void>((res) => {
            this.unsubAnswers = onSnapshot(collection(db, "users", this.$store.state.user.uid, "answers"), snap => {
                snap.docChanges().forEach(change => {
                    if (change.type === "added") {
                        this.answers.push({ id: change.doc.id, ...change.doc.data() } as Answer);
                    }
                    else if (change.type === "modified") {
                        const idx = this.answers.findIndex(d => d.id === change.doc.id);
                        this.answers[idx] = { id: change.doc.id, ...change.doc.data() } as Answer;
                    }
                    else {
                        this.answers = this.answers.filter(d => d.id !== change.doc.id);
                    }
                });
                res();
            });
        });
    }

    async next() {
        if (!this.activeDay) return;
        if (!this.manager || !this.activeDay.max) return;
        this.manager.next();
        this.contentDone = this.manager.isBack(this.activeDay.max);
        if (this.manager.isNewMax(this.activeDay.max)) {
            this.setMax();
        }
        else {
            this.setCurrent();
        }
    }

    async back() {
        if (!this.activeDay) return;
        if (!this.manager || !this.activeDay.max) return;
        this.manager.prev();
        this.contentDone = this.manager.isBack(this.activeDay.max);
        this.setCurrent();
    }

    @Emit()
    async complete() {
        if (!this.manager || !this.manager.Current) return;
        setDoc(this.doc(), {
            current: this.manager.firstId,
            done: true,
            completeDate: Timestamp.now()
        }, { merge: true });
    }

    onDone() {
        this.contentDone = true;
    }

    private async setCurrent() {
        if (!this.manager || !this.manager.Current) return;
        setDoc(this.doc(), {
            current: this.manager.Current.id
        }, { merge: true });
    }

    private async setMax() {
        if (!this.manager || !this.manager.Current) return;
        setDoc(this.doc(), {
            current: this.manager.Current.id,
            max: this.manager.Current.id
        }, { merge: true });
    }
}
