
























































import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { mdiPlay, mdiPause, mdiVolumeLow, mdiVolumeMedium, mdiVolumeHigh, mdiRewind30, mdiFullscreen, mdiFullscreenExit } from "@mdi/js";
import { VidSet } from "@/models/content";

interface Tracker {
    [key: string]: number;
}

@Component({
    name: "WVideo"
})
export default class WVideo extends Vue {
    @Prop({ required: true, type: Object })
    private readonly srcset!: VidSet;

    public local = false;

    public finished = false;

    private timetracking: Tracker = {
        watchTime: 0,
        currentTime: 0
    }

    private lastUpdated: keyof Tracker = "";

    private progress = 0;
    private bufferProgress = 0;
    private volume = 1;

    private icons = {
        mdiPlay,
        mdiPause,
        mdiRewind30,
        mdiFullscreen,
        mdiFullscreenExit
    }

    private playing = false;

    private fullscreenCapable = true;

    mounted() {
        document.addEventListener("keyup", this.onKeyUp);
        document.addEventListener("fullscreenchange", this.onFullscreenChane);
        const v = this.$refs["video-container"] as HTMLDivElement;
        if (!v.requestFullscreen)
            this.fullscreenCapable = false;
    }

    beforeDestroy() {
        document.removeEventListener("keyup", this.onKeyUp);
        document.removeEventListener("fullscreenchange", this.onFullscreenChane);
    }

    onKeyUp(e: KeyboardEvent) {
        switch(e.key) {
        case " ":
            this.handleMedia();
            break;
        }
    }

    onFullscreenChane() {
        if(!document.fullscreenElement) {
            this.fullscreen = false;
        }
    }

    get src() {
        // Prioritize lower definitions to save bandwidth
        return this.srcset.hd;
    }

    loadedMetadata(e: Event) {
        const video = e.target as HTMLVideoElement;
        if(this.timetracking["watchTime"]) {
            video.currentTime = this.timetracking["watchTime"];
        }
    }

    loadedData(e: Event) {
        const video = e.target as HTMLVideoElement;
        this.playing = !video.paused;
    }

    onProgress(e: Event) {
        const video = e.target as HTMLVideoElement;
        if(video.buffered.length > 0)
            this.bufferProgress = video.buffered.end(0) / video.duration * 100;
    }

    ended() {
        this.finished = true;
        this.$emit("ended");
    }

    timeUpdate(e: Event) {
        const v = e.target as HTMLVideoElement;
        this.progress = v.currentTime / v.duration * 100;
        // Hack until I can find a fix
        if (this.progress > 90)
            this.$emit("ended");
        if(!v.seeking) {
            if(v.currentTime > this.timetracking.watchTime) {
                this.timetracking.watchTime = v.currentTime;
                this.lastUpdated = "watchTime";
            }
            else {
                this.timetracking.currentTime = v.currentTime;
                this.lastUpdated = "currentTime";
            }
        }
    }

    async play() {
        const video = this.$refs["video"] as HTMLVideoElement;
        if(video) {
            try {
                await video.play();
                this.playing = true;
            }
            catch (error) {
                this.local = true;
                this.play();
            }
        }
    }

    pause() {
        const video = this.$refs["video"] as HTMLVideoElement;
        if(video) {
            video.pause();
            this.playing = false;
        }
    }

    private fullscreen = false;
    exitFullScreen() {
        const v = this.$refs["video-container"] as HTMLDivElement;
        if(v) {
            if(this.fullscreen) {
                this.fullscreen = false;
                document.exitFullscreen();
            }
        }
    }

    reqFullScreen() {
        const v = this.$refs["video-container"] as HTMLDivElement;
        if(v && v.requestFullscreen) {
            this.fullscreen = true;
            v.requestFullscreen();
        }
    }

    get volumeIcon() {
        if(this.volume <= 0.5)
            return mdiVolumeLow;
        if(this.volume <= 0.75)
            return mdiVolumeMedium;
        return mdiVolumeHigh;
    }

    @Watch("volume")
    onVolumeChange(value: number) {
        const video = this.$refs["video"] as HTMLVideoElement;
        video.volume = value;
    }

    rewind() {
        const v = this.$refs["video"] as HTMLVideoElement;
        let t = v.currentTime - 30;
        if(t < 0)
            t = 0;
        v.currentTime = t;
    }

    handleClick() {
        this.handleMedia();
    }

    handleMedia() {
        if(this.playing)
            this.pause();
        else
            this.play();
    }

    private t = 0;
    private none = false;
    mousemove(e: MouseEvent) {
        if(e.target) {
            this.none = false;
            clearTimeout(this.t);
            this.t = setTimeout(() => {
                this.none = true;
            }, 2000);
        }
    }

    mouseleave() {
        clearTimeout(this.t);
    }
}
